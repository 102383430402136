import React, { useState, useLayoutEffect } from 'react';

import { withFirebase } from '../Firebase';

const SIGN_IN_METHODS = [
  {
    id: 'password',
    provider: null,
  },
  {
    id: 'google.com',
    provider: 'googleProvider',
  },
  {
    id: 'facebook.com',
    provider: 'facebookProvider',
  },
];

const LoginManagement = props => {
  const [state, setState] = useState({ activeSignInMethods: [], error: null, firebaseStarted: false });
  const fetchSignInMethods = () => {
    props.firebase.auth
      .fetchSignInMethodsForEmail(props.authUser.email)
      .then(activeSignInMethods =>
        setState((state) => ({ ...state, activeSignInMethods, error: null })),
      )
      .catch(error => setState((state) => ({ ...state, error })));
  };

  useLayoutEffect(() => {
    if (props.firebase && !state.firebaseStarted) {
      setState(state => ({
        ...state,
        firebaseStarted: true,
      }));
      fetchSignInMethods();
    }
  }
      // eslint-disable-next-line react-hooks/exhaustive-deps
      ,[props.firebase, state.firebaseStarted, props.authUser.email]);

  const onSocialLoginLink = provider => {
    props.firebase.auth.currentUser
      .linkWithPopup(props.firebase[provider])
      .then(fetchSignInMethods)
      .catch(error => setState(state => ({ ...state, error })));
  };

  const onDefaultLoginLink = password => {
    const credential = props.firebase.emailAuthProvider.credential(
      props.authUser.email,
      password,
    );

    props.firebase.auth.currentUser
      .linkAndRetrieveDataWithCredential(credential)
      .then(fetchSignInMethods)
      .catch(error => setState(state => ({ ...state, error })));
  };

  const onUnlink = providerId => {
    props.firebase.auth.currentUser
      .unlink(providerId)
      .then(fetchSignInMethods)
      .catch(error => setState(state => ({ ...state, error })));
  };

  const { activeSignInMethods, error } = state;

  return (
    <div>
      Sign In Methods:
      <ul>
        {SIGN_IN_METHODS.map(signInMethod => {
          const onlyOneLeft = activeSignInMethods.length === 1;
          const isEnabled = activeSignInMethods.includes(
            signInMethod.id,
          );
          return (
            <li key={signInMethod.id}>
              {signInMethod.id === 'password' ? (
                <DefaultLoginToggle
                  onlyOneLeft={onlyOneLeft}
                  isEnabled={isEnabled}
                  signInMethod={signInMethod}
                  onLink={onDefaultLoginLink}
                  onUnlink={onUnlink}
                />
              ) : (
                <SocialLoginToggle
                  onlyOneLeft={onlyOneLeft}
                  isEnabled={isEnabled}
                  signInMethod={signInMethod}
                  onLink={onSocialLoginLink}
                  onUnlink={onUnlink}
                />
              )}
            </li>
          );
        })}
      </ul>
      {error && error.message}
    </div>
  );
}


const SocialLoginToggle = ({
  onlyOneLeft,
  isEnabled,
  signInMethod,
  onLink,
  onUnlink,
}) =>
  isEnabled ? (
    <button
      type="button"
      onClick={() => onUnlink(signInMethod.id)}
      disabled={onlyOneLeft}
    >
      Deactivate {signInMethod.id}
    </button>
  ) : (
    <button
      type="button"
      onClick={() => onLink(signInMethod.provider)}
    >
      Link {signInMethod.id}
    </button>
  );

const DefaultLoginToggle = props => {
  const [state, setState] = useState({ passwordOne: '', passwordTwo: '' });

  const onSubmit = event => {
    event.preventDefault();

    props.onLink(state.passwordOne);
    setState(state => ({ ...state, passwordOne: '', passwordTwo: '' }));
  };

  const onChange = event => {
    setState(state => ({ ...state, [event.target.name]: event.target.value }));
  };
  const {
    onlyOneLeft,
    isEnabled,
    signInMethod,
    onUnlink,
  } = props;

  const { passwordOne, passwordTwo } = state;

  const isInvalid =
    passwordOne !== passwordTwo || passwordOne === '';

  return isEnabled ? (
    <button
      type="button"
      onClick={() => onUnlink(signInMethod.id)}
      disabled={onlyOneLeft}
    >
      Deactivate {signInMethod.id}
    </button>
  ) : (
    <form onSubmit={onSubmit}>
      <input
        name="passwordOne"
        value={passwordOne}
        onChange={onChange}
        type="password"
        placeholder="New Password"
      />
      <input
        name="passwordTwo"
        value={passwordTwo}
        onChange={onChange}
        type="password"
        placeholder="Confirm New Password"
      />

      <button disabled={isInvalid} type="submit">
        Link {signInMethod.id}
      </button>
    </form>
  );
}

export default withFirebase(LoginManagement);
